<template>
  <a-row style="padding-right: 10px">
    <a-col class="content-row" :span="24">
      <h3>Carta de Crédito</h3>
      <div class="list">
        <a-row class="infos" :gutter="20">
          <a-col :span="8">
            <span class="label">Valor total:</span>
            {{
              formatPricePtBr(
                tempContract.payment_methods_payment_card_total_value
              )
            }}
          </a-col>

          <a-col :span="8">
            <span class="label">Valor da Lucratividade:</span>
            {{
              formatPricePtBr(
                tempContract.payment_methods_payment_card_total_profit_value
              )
            }}
          </a-col>

          <a-col
            v-if="tempContract.payment_methods_payment_card_voyage_id"
            :span="8"
          >
            <span class="label"> Número do voyage:</span>
            {{ tempContract.payment_methods_payment_card_voyage_id }}
          </a-col>
        </a-row>
      </div>
    </a-col>
  </a-row>
</template>

<script>
import formatThings from "@/mixins/general/formatThings.js";

export default {
  name: "ContractPaymentMethodsPaymentCardDetails",
  props: {
    tempContract: Object,
  },
  mixins: [formatThings],
  data() {
    return {
      paymentCardRows: [],
    };
  },
  mounted() {
    this.paymentCardRows =
      JSON.parse(this.tempContract.payment_card_rows) ?? [];
  },
};
</script>

<style lang="sass" scoped>
.content-row
  font-size: 12px
  font-weight: 600
  color: #333
  .label
    display: block
    font-size: 11px
    font-weight: 500
    color: #aaa
  h3
    padding: 0 0 6px 0
    font-size: 14px
    font-weight: 600
    margin-bottom: 15px
    color: #ed0e93
    text-transform: uppercase
    border-bottom: 1px solid #ececec
</style>
