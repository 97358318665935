<template>
  <aRow>
    <aCol class="content-row" :span="24">
      <h3>
        <aRow type="flex" justify="space-between">
          <aCol> Links de Pagamento </aCol>
          <aCol> </aCol>
        </aRow>
      </h3>

      <div
        v-for="(link, index) in paymentLinkRows"
        class="credit-card"
        :key="index"
      >
        <aRow class="infos" :gutter="20">
          <aCol class="mb-10" :span="24">
            <h4><a-icon type="link" /> LINK PAGAMENTO #{{ link.id }}</h4>
          </aCol>
          <aCol :span="6">
            <span class="label">Total Link de Pagamento:</span>
            {{
              formatPricePtBr(
                tempContract[
                  `payment_methods_payment_link_total_value_${link.id}`
                ]
              )
            }}
          </aCol>

          <aCol :span="3">
            <span class="label">Parcelas:</span>
            {{
              tempContract[
                `payment_methods_payment_link_installments_${link.id}`
              ]
            }}
          </aCol>

          <aCol :span="4">
            <span class="label">Valor parcelas:</span>
            {{
              formatMultiPricePtBr(
                tempContract[
                  `payment_methods_payment_link_installments_value_${link.id}`
                ]
              )
            }}
          </aCol>
        </aRow>

        <aRow class="mt-10" :gutter="20">
          <aCol :span="6">
            <span class="label">Maquininha:</span>
            {{
              tempContract[
                `payment_methods_payment_link_machine_id_${link.id}`
              ] == 14
                ? "Direto com fornecedor"
                : theCreditCardMachine(
                    tempContract[
                      `payment_methods_payment_link_machine_id_${link.id}`
                    ]
                  )
            }}
          </aCol>

          <aCol
            v-if="
              tempContract[
                `payment_methods_payment_link_machine_id_${link.id}`
              ] == 14
            "
            :span="10"
          >
            <span class="label">Fornecedor:</span>
            {{
              tempContract[
                `payment_methods_payment_link_machine_supplier_${link.id}`
              ]
            }}
          </aCol>

          <aCol
            v-if="
              tempContract[
                `payment_methods_payment_link_machine_id_${link.id}`
              ] != 14
            "
            :span="4"
          >
            <span class="label">Taxa PDQ:</span>
            <!-- {{
                formatMultiPricePtBr(
                  tempContract[
                    `payment_methods_payment_link_tax_value_${link.id}`
                  ]
                )
              }} -->
          </aCol>

          <aCol
            v-if="
              tempContract[
                `payment_methods_payment_link_machine_id_${link.id}`
              ] != 14
            "
            :span="4"
          >
            <span class="label">Taxa Adm:</span>
            <!-- {{
                formatMultiPricePtBr(
                  tempContract[
                    `payment_methods_payment_link_advance_fee_value_${link.id}`
                  ]
                )
              }} -->
          </aCol>

          <aCol
            v-if="
              tempContract[
                `payment_methods_payment_link_machine_id_${link.id}`
              ] != 14
            "
            :span="4"
          >
            <span class="label">Total Taxas:</span>
            <!-- {{
                formatMultiPricePtBr(
                  tempContract[
                    `payment_methods_payment_link_total_tax_discount_${link.id}`
                  ]
                )
              }} -->
          </aCol>

          <aCol
            class="f10"
            v-if="
              tempContract[`payment_methods_payment_link_url_${link.id}`] !==
              undefined
            "
            :span="24"
          >
            <span class="label">Link:</span>
            {{ tempContract[`payment_methods_payment_link_url_${link.id}`] }}
          </aCol>
        </aRow>
      </div>
    </aCol>
  </aRow>
</template>

<script>
import formatThings from "@/mixins/general/formatThings.js";
import cardMachinesMixins from "@/mixins/card-machines/cardMachinesMixins.js";
import productSupplierMixins from "@/mixins/product-suppliers/productSupplierMixins.js";

export default {
  name: "ContractPaymentMethodsCreditCardDetails",
  props: {
    tempContract: Object,
  },
  mixins: [formatThings, cardMachinesMixins, productSupplierMixins],
  data() {
    return {
      paymentLinkRows: [],
    };
  },
  mounted() {
    this.paymentLinkRows = this.tempContract.payment_link_rows
      ? JSON.parse(this.tempContract.payment_link_rows)
      : [];

    this.cardMachines.filters.status.selected = "Ativo";
    this.getCardMachines();
  },
  methods: {
    theCreditCardMachine(id) {
      let selected = this.cardMachines.list.filter((machine) => {
        return machine.id == id;
      });

      return selected ? selected[0]?.name : "";
    },
  },
};
</script>

<style lang="sass" scoped>
.credit-card
  margin-bottom: 20px
  .card-owner
    border: 1px solid #ccc
    padding: 16px
    border-radius: 6px
  h4
    font-size: 14px
    font-weight: 600
    color: #ed0e93
    text-transform: uppercase
    margin: 0
    padding: 0
.credit-card:last-child
  border: 0
  margin-bottom: 0px

.content-row
  font-size: 12px
  font-weight: 600
  color: #333
  .label
    display: block
    font-size: 11px
    font-weight: 500
    color: #aaa
  h3
    padding: 0 0 6px 0
    font-size: 14px
    font-weight: 600
    margin-bottom: 15px
    color: #ed0e93
    text-transform: uppercase
    border-bottom: 1px solid #ececec
</style>
