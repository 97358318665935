<template>
  <a-row style="padding-right: 10px">
    <a-col class="content-row" :span="24">
      <h3>BOLETO BANCÁRIO</h3>
      <div class="list">
        <a-row class="infos" :gutter="20">
          <a-col :span="6">
            <span class="label">Valor total do boleto:</span>
            {{
              formatPricePtBr(
                tempContract.payment_methods_billing_ticket_total_value
              )
            }}
          </a-col>

          <a-col :span="3">
            <span class="label">Parcelas:</span>
            {{ tempContract.payment_methods_billing_ticket_installments }}
          </a-col>

          <a-col :span="6">
            <span class="label">Emissor:</span>
            {{ theIssuer(tempContract.payment_methods_billing_ticket_tax_id) }}
          </a-col>

          <a-col :span="4">
            <span class="label"> Total Taxas :</span>
            {{ tempContract.payment_methods_billing_ticket_tax_value }}
          </a-col>

          <a-col :span="4">
            <span class="label">CPF:</span>
            {{ tempContract.payment_methods_billing_ticket_CPF }}
          </a-col>

          <a-col class="mt-20" :span="12">
            <a-row class="f12" :gutter="[20, 10]" style="color: #aaa">
              <a-col :span="3">
                <strong> # </strong>
              </a-col>
              <a-col :span="8">
                <strong> VALOR </strong>
              </a-col>

              <a-col :span="10">
                <strong> VENCIMENTO </strong>
              </a-col>
            </a-row>
            <a-row class="f12" :gutter="[20, 10]">
              <a-col :span="3">
                <strong> 1ª </strong>
              </a-col>
              <a-col :span="8">
                {{
                  tempContract[`payment_methods_billing_ticket_installment_1`]
                }}
              </a-col>

              <a-col :span="10">
                {{
                  formatMultiDates(
                    tempContract[
                      `payment_methods_billing_ticket_installment_1_expiration_date`
                    ]
                  )
                }}
              </a-col>
            </a-row>
            <a-row
              class="f12"
              v-for="field in billingTicketinstallments"
              :key="field"
              :gutter="[20, 10]"
            >
              <a-col :span="3">
                <strong> {{ field.id }}ª </strong>
              </a-col>
              <a-col :span="8">
                {{
                  tempContract[
                    `payment_methods_billing_ticket_installment_${field.id}`
                  ]
                }}
              </a-col>

              <a-col :span="10">
                {{
                  formatMultiDates(
                    tempContract[
                      `payment_methods_billing_ticket_installment_${field.id}_expiration_date`
                    ]
                  )
                }}
              </a-col>
            </a-row>
          </a-col>

          <a-col
            class="mt-20"
            v-if="tempContract.payment_methods_billing_ticket_observation"
            :span="24"
          >
            <span class="label">Observações:</span>
            {{ tempContract.payment_methods_billing_ticket_observation }}
          </a-col>
        </a-row>
      </div>
    </a-col>
  </a-row>
</template>

<script>
import formatThings from "@/mixins/general/formatThings.js";
import billingTicketsMixins from "@/mixins/billing-tickets/billingTicketsMixins.js";

export default {
  name: "ContractPaymentMethodsBillingTicketDetails",
  props: {
    tempContract: Object,
  },
  mixins: [formatThings, billingTicketsMixins],
  data() {
    return { billingTicketinstallments: [] };
  },
  mounted() {
    this.billingTickets.filters.status.selected = "Ativo";
    this.getBillingTickets();

    if (
      this.tempContract["payment_methods_billing_ticket_installments"] !=
      undefined
    ) {
      let installments = parseInt(
        this.tempContract["payment_methods_billing_ticket_installments"]
      );

      for (let i = 2; i < installments + 1; i++) {
        this.billingTicketinstallments.push({ id: i });
      }
    }
  },
  methods: {
    theIssuer(id) {
      let theIssuer = this.billingTickets.list.filter((bank) => {
        return bank.id == id;
      });

      return theIssuer ? theIssuer[0].name : "";
    },
  },
};
</script>

<style lang="sass" scoped>
.content-row
    font-size: 12px
    color: #333
    font-weight: 600
    .label
        display: block
        font-size: 11px
        font-weight: 500
        color: #aaa
    h3
        padding: 0 0 6px 0
        font-size: 14px
        font-weight: 600
        margin-bottom: 15px
        color: #ed0e93
        text-transform: uppercase
        border-bottom: 1px solid #ececec
</style>
