var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-row',{staticStyle:{"padding-right":"10px"}},[_c('a-col',{staticClass:"content-row",attrs:{"span":24}},[_c('h3',[_vm._v("TRANSFERÊNCIA BANCÁRIA")]),_vm._v(" "),_c('div',{staticClass:"list"},[_c('a-row',{staticClass:"infos",attrs:{"gutter":20}},[_c('a-col',{attrs:{"span":8}},[_c('span',{staticClass:"label"},[_vm._v("Valor total da Transferência:")]),_vm._v("\n          "+_vm._s(_vm.formatPricePtBr(
              _vm.tempContract.payment_methods_bank_transfer_total_value
            ))+"\n        ")]),_vm._v(" "),_c('a-col',{attrs:{"span":8}},[_c('span',{staticClass:"label"},[_vm._v("Data de recebimento transferência:")]),_vm._v("\n          "+_vm._s(_vm.formatMultiDates(
              _vm.tempContract.payment_methods_bank_transfer_receipt_date
            ))+"\n        ")]),_vm._v(" "),(_vm.banksList.length > 0)?_c('a-col',{staticClass:"mt-10",attrs:{"span":24}},[_c('span',{staticClass:"label"},[_vm._v("Dados Banco:")]),_vm._v(" "),_c('strong',{staticStyle:{"font-weight":"600"}},[_vm._v("\n            "+_vm._s(_vm.theBank(_vm.tempContract.payment_methods_bank_transfer_bank)
                .company_name)+"\n          ")]),_vm._v(" "),_c('a-divider',{attrs:{"type":"vertical"}}),_vm._v(" "),_c('strong',{staticStyle:{"font-weight":"600"}},[_vm._v("CNPJ/PIX:")]),_vm._v("\n          "+_vm._s(_vm.theBank(_vm.tempContract.payment_methods_bank_transfer_bank).cnpj)+"\n          "),_c('div',[_vm._v("\n            Agência:\n            "),_c('strong',{staticStyle:{"font-weight":"600"}},[_vm._v("\n              "+_vm._s(_vm.theBank(_vm.tempContract.payment_methods_bank_transfer_bank)
                  .agency))]),_vm._v(" "),_c('a-divider',{attrs:{"type":"vertical"}}),_vm._v("\n            Conta:\n            "),_c('strong',{staticStyle:{"font-weight":"600"}},[_vm._v("\n              "+_vm._s(_vm.theBank(_vm.tempContract.payment_methods_bank_transfer_bank)
                  .account))]),_vm._v(" "),_c('a-divider',{attrs:{"type":"vertical"}}),_vm._v("\n            "+_vm._s(_vm.theBank(_vm.tempContract.payment_methods_bank_transfer_bank)
                .number)+"\n            -\n            "+_vm._s(_vm.theBank(_vm.tempContract.payment_methods_bank_transfer_bank).name)+"\n          ")],1)],1):_vm._e()],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }