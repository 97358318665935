<template>
  <div>
    <aRow>
      <aCol class="content-row" :span="24">
        <h3>
          <aRow type="flex" justify="space-between">
            <aCol> Cartão de Crédito </aCol>

            <aCol>
              <a-tooltip placement="top" title="Ver dados do cartão">
                <a-button
                  type="primary"
                  @click="showPasswordModal = true"
                  shape="circle"
                  icon="eye"
                  size="small"
                />
              </a-tooltip>
            </aCol>
          </aRow>
        </h3>

        <div
          v-for="(cCard, index) in creditCardRows"
          class="credit-card"
          :key="index"
        >
          <aRow class="infos" :gutter="20">
            <aCol class="mb-10" :span="24">
              <h4>
                <a-icon type="credit-card" /> #{{ cCard.id }} -
                {{
                  tempContract[
                    `payment_methods_credit_card_owner_${cCard.id}`
                  ] == "client"
                    ? "Contratante"
                    : "Terceiro"
                }}
              </h4>
            </aCol>
            <aCol :span="6">
              <span class="label">Total Cartão de Crédito:</span>
              {{
                formatPricePtBr(
                  tempContract[
                    `payment_methods_credit_card_total_value_${cCard.id}`
                  ]
                )
              }}
            </aCol>

            <aCol :span="3">
              <span class="label">Parcelas:</span>
              {{
                tempContract[
                  `payment_methods_credit_card_installments_${cCard.id}`
                ]
              }}
            </aCol>

            <aCol :span="5">
              <span class="label">Valor da 1ª parcela:</span>

              {{
                formatMultiPricePtBr(
                  tempContract[
                    `payment_methods_credit_card_first_installment_value_${cCard.id}`
                  ]
                )
              }}
            </aCol>

            <aCol :span="4">
              <span class="label">Demais parcelas:</span>
              {{
                formatMultiPricePtBr(
                  tempContract[
                    `payment_methods_credit_card_installments_value_${cCard.id}`
                  ]
                )
              }}
            </aCol>

            <aCol :span="6">
              <span class="label">Proprietário do cartão:</span>
              {{
                tempContract[`payment_methods_credit_card_owner_${cCard.id}`] ==
                "client"
                  ? "Contratante"
                  : "Terceiro"
              }}
            </aCol>
          </aRow>

          <aRow
            class="mt-20 card-owner"
            v-if="
              tempContract[`payment_methods_credit_card_owner_${cCard.id}`] !=
              'client'
            "
          >
            <aCol class="mb-10" :span="24">
              <h4>
                <a-icon type="user" />
                {{
                  tempContract[
                    `payment_methods_credit_card_owner_${cCard.id}_name`
                  ]
                }}
              </h4>
            </aCol>

            <aCol :span="6">
              <span class="label">CPF:</span>
              {{
                tempContract[
                  `payment_methods_credit_card_owner_${cCard.id}_CPF`
                ]
              }}
            </aCol>

            <aCol :span="6">
              <span class="label">Celular:</span>
              {{
                tempContract[
                  `payment_methods_credit_card_owner_${cCard.id}_mobile_phone`
                ]
              }}
            </aCol>

            <aCol :span="6">
              <span class="label">Telefone fixo:</span>
              {{
                tempContract[
                  `payment_methods_credit_card_owner_${cCard.id}_phone`
                ]
              }}
            </aCol>

            <aCol :span="6">
              <span class="label">CEP:</span>
              {{
                tempContract[
                  `payment_methods_credit_card_owner_${cCard.id}_CEP`
                ]
              }}
            </aCol>

            <aCol class="mt-10" :span="20">
              <span class="label">Logradouro:</span>
              {{
                tempContract[
                  `payment_methods_credit_card_owner_${cCard.id}_location_street`
                ]
              }}
            </aCol>
            <aCol class="mt-10" :span="4">
              <span class="label">Número:</span>
              {{
                tempContract[
                  `payment_methods_credit_card_owner_${cCard.id}_location_number`
                ]
              }}
            </aCol>

            <aCol class="mt-10" :span="8">
              <span class="label">Complemento:</span>
              {{
                tempContract[
                  `payment_methods_credit_card_owner_${cCard.id}_location_complement`
                ]
              }}
            </aCol>

            <aCol class="mt-10" :span="8">
              <span class="label">Bairro:</span>
              {{
                tempContract[
                  `payment_methods_credit_card_owner_${cCard.id}_location_neighborhood`
                ]
              }}
            </aCol>

            <aCol class="mt-10" :span="6">
              <span class="label">Cidade:</span>
              {{
                tempContract[
                  `payment_methods_credit_card_owner_${cCard.id}_location_city`
                ]
              }}
            </aCol>

            <aCol class="mt-10" :span="2">
              <span class="label">Estado:</span>
              {{
                tempContract[
                  `payment_methods_credit_card_owner_${cCard.id}_location_state`
                ]
              }}
            </aCol>
          </aRow>

          <aRow class="mt-10" :gutter="20">
            <aCol :span="6">
              <span class="label">Maquininha:</span>
              {{
                tempContract[
                  `payment_methods_credit_card_machine_id_${cCard.id}`
                ] == 14
                  ? "Direto com fornecedor"
                  : theCreditCardMachine(
                      tempContract[
                        `payment_methods_credit_card_machine_id_${cCard.id}`
                      ]
                    )
              }}
            </aCol>

            <aCol
              v-if="
                tempContract[
                  `payment_methods_credit_card_machine_id_${cCard.id}`
                ] == 14
              "
              :span="10"
            >
              <span class="label">Fornecedor:</span>
              {{
                tempContract[
                  `payment_methods_credit_card_machine_supplier_${cCard.id}`
                ]
              }}
            </aCol>

            <aCol
              v-if="
                tempContract[
                  `payment_methods_credit_card_machine_id_${cCard.id}`
                ] != 14
              "
              :span="4"
            >
              <span class="label">Taxa PDQ:</span>
              {{
                formatMultiPricePtBr(
                  tempContract[
                    `payment_methods_credit_card_tax_value_${cCard.id}`
                  ]
                )
              }}
            </aCol>

            <aCol
              v-if="
                tempContract[
                  `payment_methods_credit_card_machine_id_${cCard.id}`
                ] != 14
              "
              :span="4"
            >
              <span class="label">Taxa Adm:</span>
              {{
                formatMultiPricePtBr(
                  tempContract[
                    `payment_methods_credit_card_advance_fee_value_${cCard.id}`
                  ]
                )
              }}
            </aCol>

            <aCol
              v-if="
                tempContract[
                  `payment_methods_credit_card_machine_id_${cCard.id}`
                ] != 14
              "
              :span="4"
            >
              <span class="label">Total Taxas:</span>
              {{
                formatMultiPricePtBr(
                  tempContract[
                    `payment_methods_credit_card_total_tax_discount_${cCard.id}`
                  ]
                )
              }}
            </aCol>
          </aRow>

          <aRow :gutter="[20, 0]">
            <aCol :span="24">
              <div
                class="travel-credit-card"
                style="margin: 12px -12px 0px; width: 102.7%; zoom: 0.8"
              >
                <div class="card">
                  <div class="front">
                    <span class="chip">
                      <img src="@/assets/images/chip.png" alt="chip" />
                    </span>

                    <span class="flag">
                      {{
                        tempCreditCard[
                          `payment_methods_credit_card_client_flag_${cCard.id}`
                        ]
                          ? tempCreditCard[
                              `payment_methods_credit_card_client_flag_${cCard.id}`
                            ]
                          : "xxxxxxx"
                      }}
                    </span>
                    <span class="numbers">
                      {{
                        tempCreditCard[
                          `payment_methods_credit_card_client_number_${cCard.id}`
                        ]
                          ? tempCreditCard[
                              `payment_methods_credit_card_client_number_${cCard.id}`
                            ]
                          : "xxxx xxxx xxxx xxxx"
                      }}
                    </span>
                    <span class="name">
                      <span class="txt">Nome impresso</span>
                      <span class="value">
                        {{
                          tempCreditCard[
                            `payment_methods_credit_card_client_name_${cCard.id}`
                          ]
                            ? tempCreditCard[
                                `payment_methods_credit_card_client_name_${cCard.id}`
                              ]
                            : "xxxxxxxxxxxxxxxxx"
                        }}</span
                      >
                    </span>
                    <span class="expires">
                      <span class="txt">Expiração</span>
                      <span class="value">
                        {{
                          tempCreditCard[
                            `payment_methods_credit_card_client_month_${cCard.id}`
                          ]
                            ? tempCreditCard[
                                `payment_methods_credit_card_client_month_${cCard.id}`
                              ]
                            : "xx/xxxx"
                        }}
                        {{
                          tempCreditCard[
                            `payment_methods_credit_card_client_year_${cCard.id}`
                          ]
                            ? "/" +
                              tempCreditCard[
                                `payment_methods_credit_card_client_year_${cCard.id}`
                              ]
                            : ""
                        }}</span
                      >
                    </span>
                  </div>
                  <div class="back">
                    <span class="blackbar"></span>
                    <span
                      class="cvv"
                      v-show="
                        tempContract[
                          `payment_methods_credit_card_have_cvv_${cCard.id}`
                        ]
                      "
                    >
                      <span class="txt">CVV</span>
                      <span class="value"> xxx </span>
                    </span>
                  </div>
                </div>
              </div>
            </aCol>
          </aRow>
        </div>
      </aCol>
    </aRow>

    <aModal
      :visible="showPasswordModal"
      :footer="false"
      :width="300"
      @cancel="showPasswordModal = false"
    >
      <template #title> <aIcon type="lock" /> Verificação </template>
      <LoginFormModal
        v-if="showPasswordModal"
        :updateStorageData="false"
        @verificationSuccessfully="verificationSuccessfully"
      />
    </aModal>
  </div>
</template>

<script>
import formatThings from "@/mixins/general/formatThings.js";
import cardMachinesMixins from "@/mixins/card-machines/cardMachinesMixins.js";
import productSupplierMixins from "@/mixins/product-suppliers/productSupplierMixins.js";
import LoginFormModal from "../../login/LoginFormModal.vue";

export default {
  name: "ContractPaymentMethodsCreditCardDetails",
  props: {
    tempContract: Object,
  },
  components: { LoginFormModal },
  mixins: [formatThings, cardMachinesMixins, productSupplierMixins],
  data() {
    return {
      creditCardRows: [],
      showPasswordModal: false,
      tempCreditCard: {},
      authCode: "",
      userPassword: null,
      getCardDataLoading: false,
    };
  },
  mounted() {
    this.creditCardRows = this.tempContract.credit_card_rows
      ? JSON.parse(this.tempContract.credit_card_rows)
      : [];

    this.cardMachines.filters.status.selected = "Ativo";
    this.getCardMachines();
  },
  methods: {
    theCreditCardMachine(id) {
      let selected = this.cardMachines.list.filter((machine) => {
        return machine.id == id;
      });

      return selected.length ? selected[0].name : "---";
    },
    verificationSuccessfully(verificationData) {
      this.showPasswordModal = false;
      this.getCardDataLoading = true;

      this.$hayaApi
        .post(`/contracts/credit-cards-data`, {
          ...verificationData,
          contractId: this.tempContract.id,
        })
        .then(({ data }) => {
          this.$message.success(data.message);
          this.tempCreditCard = data.card;
        })
        .finally(() => {
          this.getCardDataLoading = false;
        });
    },
  },
};
</script>

<style lang="sass" scoped>
.credit-card
  margin-bottom: 20px
  .card-owner
    border: 1px solid #ccc
    padding: 16px
    border-radius: 6px
  h4
    font-size: 14px
    font-weight: 600
    color: #ed0e93
    text-transform: uppercase
    margin: 0
    padding: 0
.credit-card:last-child
  border: 0
  margin-bottom: 0px

.content-row
  font-size: 12px
  color: #333
  font-weight: 600
  .label
    display: block
    font-size: 11px
    font-weight: 500
    color: #aaa
  h3
    padding: 0 0 6px 0
    font-size: 14px
    font-weight: 600
    margin-bottom: 15px
    color: #ed0e93
    text-transform: uppercase
    border-bottom: 1px solid #ececec
</style>
