var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-row',{staticStyle:{"padding-right":"10px"}},[_c('a-col',{staticClass:"content-row",attrs:{"span":24}},[_c('h3',[_vm._v("BOLETO BANCÁRIO")]),_vm._v(" "),_c('div',{staticClass:"list"},[_c('a-row',{staticClass:"infos",attrs:{"gutter":20}},[_c('a-col',{attrs:{"span":6}},[_c('span',{staticClass:"label"},[_vm._v("Valor total do boleto:")]),_vm._v("\n          "+_vm._s(_vm.formatPricePtBr(
              _vm.tempContract.payment_methods_billing_ticket_total_value
            ))+"\n        ")]),_vm._v(" "),_c('a-col',{attrs:{"span":3}},[_c('span',{staticClass:"label"},[_vm._v("Parcelas:")]),_vm._v("\n          "+_vm._s(_vm.tempContract.payment_methods_billing_ticket_installments)+"\n        ")]),_vm._v(" "),_c('a-col',{attrs:{"span":6}},[_c('span',{staticClass:"label"},[_vm._v("Emissor:")]),_vm._v("\n          "+_vm._s(_vm.theIssuer(_vm.tempContract.payment_methods_billing_ticket_tax_id))+"\n        ")]),_vm._v(" "),_c('a-col',{attrs:{"span":4}},[_c('span',{staticClass:"label"},[_vm._v(" Total Taxas :")]),_vm._v("\n          "+_vm._s(_vm.tempContract.payment_methods_billing_ticket_tax_value)+"\n        ")]),_vm._v(" "),_c('a-col',{attrs:{"span":4}},[_c('span',{staticClass:"label"},[_vm._v("CPF:")]),_vm._v("\n          "+_vm._s(_vm.tempContract.payment_methods_billing_ticket_CPF)+"\n        ")]),_vm._v(" "),_c('a-col',{staticClass:"mt-20",attrs:{"span":12}},[_c('a-row',{staticClass:"f12",staticStyle:{"color":"#aaa"},attrs:{"gutter":[20, 10]}},[_c('a-col',{attrs:{"span":3}},[_c('strong',[_vm._v(" # ")])]),_vm._v(" "),_c('a-col',{attrs:{"span":8}},[_c('strong',[_vm._v(" VALOR ")])]),_vm._v(" "),_c('a-col',{attrs:{"span":10}},[_c('strong',[_vm._v(" VENCIMENTO ")])])],1),_vm._v(" "),_c('a-row',{staticClass:"f12",attrs:{"gutter":[20, 10]}},[_c('a-col',{attrs:{"span":3}},[_c('strong',[_vm._v(" 1ª ")])]),_vm._v(" "),_c('a-col',{attrs:{"span":8}},[_vm._v("\n              "+_vm._s(_vm.tempContract[`payment_methods_billing_ticket_installment_1`])+"\n            ")]),_vm._v(" "),_c('a-col',{attrs:{"span":10}},[_vm._v("\n              "+_vm._s(_vm.formatMultiDates(
                  _vm.tempContract[
                    `payment_methods_billing_ticket_installment_1_expiration_date`
                  ]
                ))+"\n            ")])],1),_vm._v(" "),_vm._l((_vm.billingTicketinstallments),function(field){return _c('a-row',{key:field,staticClass:"f12",attrs:{"gutter":[20, 10]}},[_c('a-col',{attrs:{"span":3}},[_c('strong',[_vm._v(" "+_vm._s(field.id)+"ª ")])]),_vm._v(" "),_c('a-col',{attrs:{"span":8}},[_vm._v("\n              "+_vm._s(_vm.tempContract[
                  `payment_methods_billing_ticket_installment_${field.id}`
                ])+"\n            ")]),_vm._v(" "),_c('a-col',{attrs:{"span":10}},[_vm._v("\n              "+_vm._s(_vm.formatMultiDates(
                  _vm.tempContract[
                    `payment_methods_billing_ticket_installment_${field.id}_expiration_date`
                  ]
                ))+"\n            ")])],1)})],2),_vm._v(" "),(_vm.tempContract.payment_methods_billing_ticket_observation)?_c('a-col',{staticClass:"mt-20",attrs:{"span":24}},[_c('span',{staticClass:"label"},[_vm._v("Observações:")]),_vm._v("\n          "+_vm._s(_vm.tempContract.payment_methods_billing_ticket_observation)+"\n        ")]):_vm._e()],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }